export { default as common } from "./common";
import providerInfo from "./cardProviderInfo";
import cardTax from "./cardTax";
import cardAdditionalInfo from "./cardAdditionalInfo";

export const card = {
  providerInfo,
  cardTax,
  cardAdditionalInfo,
};
