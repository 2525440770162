export { default as common } from "./common";
import attachment from "./cardAttachment";
import orderClientId from "./cardOrderClientId";
import tags from "./cardTags";
import clientOperation from "./cardClientOperation";
import teams from "./cardTeams";
import additionalInfo from "./cardAdditionalInfo";
import groupedOrders from "./cardGroupedOrders";
import linehaulDetail from "./cardLinehaulDetail";
import notes from "./cardNotes";
import comments from "./cardComments";
import statusLog from "./cardStatusLog";
import logisticsInfo from "./cardLogisticsInfo";
import eventLogs from "./cardEventLogs";
import billing from "./cardBilling";
import articles from "./cardArticles";
import exportsMenu from "./exportsMenu";

export const card = {
  attachment,
  tags,
  clientOperation,
  orderClientId,
  teams,
  additionalInfo,
  groupedOrders,
  linehaulDetail,
  notes,
  comments,
  statusLog,
  logisticsInfo,
  eventLogs,
  billing,
  articles,
};

export const exports = {
  ...exportsMenu,
};
