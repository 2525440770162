import areq from "@/common/helpers/aReq";
import AreqTools from "@letsgoi/areq";
import { EMPTY_STRING } from "@/common/helpers/constants";
import { INVOICE, ORDER_FLOWS, ORDERS, TAGS } from "./paths";
import type { CustomField, CustomFieldPatch } from "@/types/CustomFields";

const {
  setBody,
  GET,
  PATCH,
  POST,
  DELETE,
  setRequest,
  fileData,
  jsonData,
  setPassport,
  isNotEmpty,
  setLetgoiHeaders,
  setHeaders,
  setAuthBearerHeader,
} = AreqTools;

export function reqGetOrders(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders"), []))
    .then(GET(`${ORDERS}${queryParams}`))
    .then(jsonData<Api<Orders.Order[]> | Orders.Order[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetOrdersCount(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders"), []))
    .then(GET(`${ORDERS}/count${queryParams}`))
    .then(jsonData<PageCount>())
    .catch((error) => Promise.reject(error));
}

export function reqPostOrder(order: Orders.OrderPayload) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(order))
    .then(setPassport(globalThis.passport("create-orders"), {}))
    .then(POST(ORDERS))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

export function reqGetOrder(reference: string) {
  return areq()
    .then(isNotEmpty(reference))
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("view-orders"), {}))
    .then(GET(`${ORDERS}/${reference}`))
    .then(jsonData<Orders.Order>())
    .catch((error) => Promise.reject(error));
}

export function reqPatchOrder(reference: string, order: Orders.OrderPayload) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(order))
    .then(setPassport(globalThis.passport("update-orders"), {}))
    .then(PATCH(`${ORDERS}/${reference}`))
    .then(jsonData<Orders.Order>())
    .catch((error) => Promise.reject(error));
}

export function storeOrdersByCsv(formdata: FormData) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(formdata))
    .then(setPassport(globalThis.passport("create-orders"), {}))
    .then(POST(`${ORDERS}/import`))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

export function reqPostAddAttachment(reference: string, formdata: FormData) {
  return areq()
    .then(setHeaders())
    .then(setAuthBearerHeader)
    .then(setBody(formdata))
    .then(setPassport(globalThis.passport("create-orders-attachments"), {}))
    .then(POST(`${ORDERS}/${reference}/attachments`))
    .then(jsonData<Orders.Attachement>())
    .catch((error) => Promise.reject(error));
}

export function updateOrdersByCsv(formdata: FormData) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(formdata))
    .then(setPassport(globalThis.passport("update-orders"), {}))
    .then(POST(`${ORDERS}/import`))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

export function reqGetExportOrdersCSV(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setRequest({ responseType: "arraybuffer" }))
    .then(setPassport(globalThis.passport("export-orders"), []))
    .then(GET(`${ORDERS}/export${queryParams}`))
    .then(fileData())
    .catch((error) => Promise.reject(error));
}

export function reqGetDownloadStatusHistory(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setRequest({ responseType: "arraybuffer" }))
    .then(setPassport(globalThis.passport("export-orders-status_history"), []))
    .then(GET(`${ORDERS}/statuses/export${queryParams}`))
    .then(fileData())
    .catch((error) => Promise.reject(error));
}

export function reqGetDownloadOrderArticles(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setRequest({ responseType: "arraybuffer" }))
    .then(setPassport(globalThis.passport("export-orders-articles"), []))
    .then(GET(`${ORDERS}/articles/export${queryParams}`))
    .then(fileData())
    .catch((error) => Promise.reject(error));
}

export function reqGetDownloadOrderInvoicesIncome(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setRequest({ responseType: "arraybuffer" }))
    .then(
      setPassport(
        globalThis.passport("download-orders-invoices-income_summary"),
        []
      )
    )
    .then(GET(`${ORDERS}/invoices/income-summary${queryParams}`))
    .then(fileData())
    .catch((error) => Promise.reject(error));
}

export function reqGetDownloadOrderInvoicesExpense(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setRequest({ responseType: "arraybuffer" }))
    .then(
      setPassport(
        globalThis.passport("download-orders-invoices-expense_summary"),
        []
      )
    )
    .then(GET(`${ORDERS}/invoices/expense-summary${queryParams}`))
    .then(fileData())
    .catch((error) => Promise.reject(error));
}

export function reqGetDownloadAsyncDeliveryNotes(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(
      setPassport(globalThis.passport("download-orders-delivery_notes"), {})
    )
    .then(GET(`${ORDERS}/delivery-notes/bulk${queryParams}`))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

export function reqGetDownloadAsyncShippingLabels(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("download-orders-labels"), {}))
    .then(GET(`${ORDERS}/labels/bulk${queryParams}`))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

export function reqGetDownloadAsyncCmr(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setRequest({ responseType: "arraybuffer" }))
    .then(setPassport(globalThis.passport("download-orders-cmr_documents"), {}))
    .then(GET(`${ORDERS}/cmr-documents${queryParams}`))
    .then(fileData())
    .catch((error) => Promise.reject(error));
}

export function reqGetOrderStatusHistory(
  reference: string,
  queryParams = EMPTY_STRING
) {
  return areq()
    .then(isNotEmpty(reference))
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-status_history"), []))
    .then(GET(`${ORDERS}/${reference}/statuses${queryParams}`))
    .then(jsonData<Orders.StatusHistory[]>())
    .catch((error) => Promise.reject(error));
}

export function reqPostAddStatus(
  orderReference: string,
  status: Orders.StatusPayload
) {
  const body = {
    status: status.status,
    tracked_at: status.tracked_at,
  };
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(body))
    .then(setPassport(globalThis.passport("create-orders-status_history"), {}))
    .then(POST(`${ORDERS}/${orderReference}/statuses`))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

export function reqPostAddStatusForced(
  orderReference: string,
  status: Orders.StatusPayload
) {
  const body = {
    status: status.status,
    tracked_at: status.tracked_at,
  };
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(body))
    .then(
      setPassport(globalThis.passport("create-orders-status_history-force"), {})
    )
    .then(POST(`${ORDERS}/${orderReference}/statuses/force`))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

export function reqPatchStatus(
  id: string,
  orderReference: string,
  tracked_at: string
) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody({ tracked_at }))
    .then(setPassport(globalThis.passport("update-orders-status_history"), {}))
    .then(PATCH(`${ORDERS}/${orderReference}/statuses/${id}`))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

export function reqGetAttachments(reference: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-attachments"), []))
    .then(GET(`${ORDERS}/${reference}/attachments`))
    .then(jsonData<Orders.Attachement[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetDownloadAttachment(reference: string, id: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setRequest({ responseType: "blob" }))
    .then(setPassport(globalThis.passport("download-orders-attachments"), {}))
    .then(GET(`${ORDERS}/${reference}/attachments/${id}`))
    .then(fileData())
    .catch((error) => Promise.reject(error));
}

export function reqDeleteAttachment(reference: string, id: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("delete-orders-attachments"), {}))
    .then(DELETE(`${ORDERS}/${reference}/attachments/${id}`))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

export function reqGetOrderComments(reference: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-comments"), []))
    .then(GET(`${ORDERS}/${reference}/comments`))
    .then(jsonData<Comment[]>())
    .catch((error) => Promise.reject(error));
}

export function reqPostOrderComment(reference: string, comment: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody({ comment }))
    .then(setPassport(globalThis.passport("create-orders-comments"), {}))
    .then(POST(`${ORDERS}/${reference}/comments`))
    .then(jsonData<void>())
    .catch((error) => Promise.reject(error));
}

// TODO: LLevar a un fichero de api comun. Por tags no esta atadado a ningun modulo, cualquiera puede tener tags
export function reqGetSuggestedTags(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-tags-suggestions"), []))
    .then(GET(`${TAGS}/suggestions${queryParams}`))
    .then(jsonData<Tag[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetDowloadDeliveryNotes(reference: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setRequest({ responseType: "blob" }))
    .then(
      setPassport(globalThis.passport("download-orders-delivery_notes"), {})
    )
    .then(GET(`${ORDERS}/${reference}/delivery-notes`))
    .then(fileData())
    .catch((error) => Promise.reject(error));
}

export function reqGetDowloadLabels(
  reference: string,
  queryParams = EMPTY_STRING
) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setRequest({ responseType: "blob" }))
    .then(setPassport(globalThis.passport("download-orders-labels"), {}))
    .then(GET(`${ORDERS}/${reference}/labels${queryParams}`))
    .then(fileData())
    .catch((error) => Promise.reject(error));
}

export function reqGetOrderLogs(reference: string) {
  return areq()
    .then(isNotEmpty(reference))
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-logs"), []))
    .then(GET(`${ORDERS}/${reference}/logs`))
    .then(jsonData<Orders.OrderLog[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetStorageLocations(
  reference: string,
  queryParams = EMPTY_STRING
) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-storage_locations"), []))
    .then(GET(`${ORDERS}/${reference}/storage-locations${queryParams}`))
    .then(jsonData<Orders.StorageLocations[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetOrderArticles(
  reference: string,
  queryParams = EMPTY_STRING
) {
  return areq()
    .then(isNotEmpty(reference))
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-articles"), []))
    .then(GET(`${ORDERS}/${reference}/articles${queryParams}`))
    .then(jsonData<Orders.OrderArticle[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetOrderPackages(
  reference: string,
  queryParams = EMPTY_STRING
) {
  return areq()
    .then(isNotEmpty(reference))
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-packages"), []))
    .then(GET(`${ORDERS}/${reference}/packages${queryParams}`))
    .then(jsonData<Orders.Package[]>())
    .catch((error) => Promise.reject(error));
}
export function reqPostAddEventLog(
  reference: string,
  log: Orders.EventLogPayload
) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(log))
    .then(setPassport(globalThis.passport("create-orders-event_logs"), {}))
    .then(POST(`${ORDERS}/${reference}/event-logs`))
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

export function reqGetEventLogs(reference: string) {
  return areq()
    .then(isNotEmpty(reference))
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-event_logs"), []))
    .then(GET(`${ORDERS}/${reference}/event-logs`))
    .then(jsonData<Orders.EventLog[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetRescheduleReasons() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-order_reschedule_reasons"), []))
    .then(GET("/order-reschedule-reasons"))
    .then(jsonData<Orders.RescheduleReasons[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetGroupOrders(orderGroupId: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders"), []))
    .then(GET(`${ORDERS}?raw=true&filter[order_group_id]=${orderGroupId}`))
    .then(jsonData<Orders.Order[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetRelatedOrders(reference: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-related"), []))
    .then(GET(`${ORDERS}/${reference}/related`))
    .then(jsonData<Orders.Order[]>())
    .catch((error) => Promise.reject(error));
}

export function reqPostGenerateOrdersInvoices(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("generate-orders-invoices"), {}))
    .then(POST(`${ORDERS}/invoices${queryParams}`))
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

export function reqGetOrderFlows() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-order_flows"), []))
    .then(GET(`${ORDER_FLOWS}`))
    .then(jsonData<Orders.Flow[]>())
    .catch((error) => Promise.reject(error));
}

export function reqDeleteOrderArticle({
  reference,
  id,
}: {
  reference: string;
  id: string;
}) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("delete-orders-articles"), {}))
    .then(DELETE(`${ORDERS}/${reference}/articles/${id}`))
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

export function reqDeleteOrderPackage({
  reference,
  id,
}: {
  reference: string;
  id: string;
}) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("delete-orders-packages"), {}))
    .then(DELETE(`${ORDERS}/${reference}/packages/${id}`))
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

export function reqPostOrderArticle({
  reference,
  orderArticles,
}: {
  reference: string;
  orderArticles: Orders.OrderArticlePayload[];
}) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody({ articles: orderArticles }))
    .then(setPassport(globalThis.passport("create-orders-articles"), {}))
    .then(POST(`${ORDERS}/${reference}/articles`))
    .then(jsonData<Orders.OrderArticle[]>())
    .catch((error) => Promise.reject(error));
}

export function reqPatchOrderArticle({
  reference,
  articleId,
  orderArticle,
}: {
  reference: string;
  articleId: string;
  orderArticle: Orders.OrderArticlePayload;
}) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(orderArticle))
    .then(setPassport(globalThis.passport("update-orders-articles"), {}))
    .then(PATCH(`${ORDERS}/${reference}/articles/${articleId}`))
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

export function reqPostOrderPackage({
  reference,
  orderPackage,
}: {
  reference: string;
  orderPackage: Orders.PackagePayload;
}) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(orderPackage))
    .then(setPassport(globalThis.passport("create-orders-packages"), []))
    .then(POST(`${ORDERS}/${reference}/packages`))
    .then(jsonData<Orders.Package[]>())
    .catch((error) => Promise.reject(error));
}

export function reqPostOrderPackagesInBulk({
  reference,
  packages,
}: {
  reference: string;
  packages: Orders.PackagePayload[];
}) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody({ packages }))
    .then(setPassport(globalThis.passport("create-orders-packages"), []))
    .then(POST(`${ORDERS}/${reference}/packages/bulk`))
    .then(jsonData<Orders.Package[]>())
    .catch((error) => Promise.reject(error));
}

export function reqPatchOrderPackage({
  reference,
  orderPackage,
  packageId,
}: {
  reference: string;
  orderPackage: Orders.PackagePayload;
  packageId: string;
}) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(orderPackage))
    .then(setPassport(globalThis.passport("update-orders-packages"), {}))
    .then(PATCH(`${ORDERS}/${reference}/packages/${packageId}`))
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

export function reqGetInvoiceItems(reference: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(
      setPassport(globalThis.passport("list-orders-invoice_line_items"), [])
    )
    .then(GET(`${ORDERS}/${reference}${INVOICE}`))
    .then(jsonData<Invoicing.InvoiceItem[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetAvailableDays(
  reference: string,
  queryParams = EMPTY_STRING
) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-available_days"), []))
    .then(GET(`${ORDERS}/${reference}/available-days${queryParams}`))
    .then(jsonData<Orders.AvailableDays[]>())
    .catch((error) => Promise.reject(error));
}

export function reqPostOrdersBulk(orders) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(orders))
    .then(setPassport(globalThis.passport("create-orders"), {}))
    .then(POST(`${ORDERS}/bulk`))
    .catch((error) => Promise.reject(error));
}

export function reqGetOrdersCustomFields(reference: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-orders-custom_fields"), {}))
    .then(GET(`${ORDERS}/${reference}/custom-fields`))
    .then(jsonData<CustomField[]>())
    .catch((error) => Promise.reject(error));
}

export function reqPatchOrdersCustomFields(
  reference: string,
  payload: CustomFieldPatch[]
) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody({ custom_fields: payload }))
    .then(setPassport(globalThis.passport("update-orders-custom_fields"), {}))
    .then(PATCH(`${ORDERS}/${reference}/custom-fields`))
    .catch((error) => Promise.reject(error));
}

export function reqPostRecalculateOrderInvoiceLineItems(queryParams: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(
      setPassport(
        globalThis.passport("recalculate-orders-invoice_line_items"),
        {}
      )
    )
    .then(POST(`${ORDERS}/invoice-line-items/recalculate${queryParams}`))
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}
