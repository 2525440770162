import { ref, onMounted } from "vue";

export function useOperatingSystem() {
  const isMacOS = ref(false);
  const isMobile = ref(false);

  onMounted(() => {
    const userAgent = navigator.userAgent;
    isMacOS.value = /Macintosh|MacIntel|MacPPC|Mac68K|MacOS/.test(userAgent);
    isMobile.value = navigator.userAgentData
      ? navigator.userAgentData.mobile
      : /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  });

  return { isMacOS, isMobile };
}
