export { default as common } from "./common";
import reasonPriority from "./cardReasonPriority";
import orderReference from "./cardOrderReference";
import overview from "./cardOverview";
import additionalInfo from "./cardAdditionalInfo";
import incidenceLogs from "./cardIncidenceLogs";

export const card = {
  reasonPriority,
  orderReference,
  overview,
  additionalInfo,
  incidenceLogs,
};
