export default {
  list: {
    title: "Orders",
    button_new: "New Order",
    "button_new-upload": "Upload Massive",
    "button_new-update": "Update Massive ",
    "button_new-create": "Add Manually",
    button_add_order: "Add Order",
    "placeholder_search-input": "Search Operative",
    "section_download-orders": "Download Orders",
    section_export_routes: "Export to Plan Routes",
    "section_export_line-hauls": "Export to Linehaul",
    section_export_netsuit: "Export to Netsuite",
    "section_download-orders-sub": "Filtering to activate",
    section_bulk_actions: "Filtering or select to activate",
    "section_download-cargo": "Download Cargo Files",
    "section_download-income": "INCOME",
    "section_download-expense": "EXPENSE",
    "button_download-section-orders": "Download Orders (Max. {count})",
    "button_download-all": "Download Everything",
    "button_download-sample-csv-section": "Download Templates",
    "button_download-sample-csv": "Template CSV",
    "button_download-sample-csv-sub":
      "Download a sample to fill in and upload later",
    "button_download-all-columns": "All Columns",
    "button_download-all-columns-sub":
      "Will download all avaliable columns, including hidden",
    "button_download-current-columns": "Only Visible Columns",
    "button_download-current-columns-sub":
      "Will download just the current visible columns",
    "button_download-status-history": "Status History",
    "button_download-status-history-sub":
      "Download complete records of the order's status so far",
    "button_download-articles": "Order Articles",
    "button_download-articles-sub": "Download all items included in the orders",
    "button_download-billing-summaries": "Download Billing Summaries",
    "button_download-billing-summaries-sub":
      "Choose between Income Summary or Expense Summary",
    "button_download-incomes-summary": "Download Incomes Summary",
    "button_download-incomes-summary-sub":
      "Will download all income related to the selected orders",
    "button_download-expenses-summary": "Expenses Summary",
    "button_download-expenses-summary-sub":
      "Will download all expenses from all categories",
    "button_download-expenses-summary-last_mile": "Last Mile Expenses Summary",
    "button_download-expenses-summary-last_mile-sub":
      "Wll download a summary only for expenses on Last Mile",
    "button_download-expenses-summary-picking": "Picking Expenses Summary",
    "button_download-expenses-summary-linehaul": "Linehaul Expenses Summary",
    "button_download-expenses-summary-picking-sub":
      "Wll download a summary only for expenses on Pickup",
    "button_download-expenses-summary-linehaul-sub":
      "Wll download a summary only for expenses on Linehaul",
    "button_download-especific-columns": "Choose Especific Columns",
    "button_download-especific-columns-sub":
      "You'll be able to select the columns of your choice",
    "button_download-devlivery-notes": "Delivery Notes",
    "button_download-devlivery-notes-sub":
      "Yo can download a maximum of {count} delivery notes",
    "button_download-devlivery-notes-text":
      "Apply only to filtered or selected rows",
    "button_export-all": "Export All",
    "button_export-plan-max-limit_linehauls": "(Max. {maxExport} orders)",
    "button_export-only-selected": "Export Only Selected",
    "button_download-shipping-labels": "Shipping Labels",
    "button_download-shipping-labels-sub":
      "You can download a maximum of {count} shipping labels",
    "button_download-cmr": "CMR Document",
    "button_download-cmr-sub":
      "You can download a maximum of {count} CMR documents",
    "button_download-max-limit": "Max. {count}",
    "button_export-plan-routes": "Export All to Plan Routes",
    "button_download-invoice": "Generate Invoice",
    "button_export-plan-routes_text":
      "All orders listed will be exporter to routing tool",
    "button_export-plan-max-limit":
      "Only available for results under {maxExport} orders",
    "button_export-only-selected-routes": "Export Only Selected to Plan Routes",
    "button_export-only-selected-routes_text":
      "Only checked orders will be exported to routing tool",
    "columns-switcher_button_download": "Download this selection",
    "columns-switcher_button_cancel": "Cancel",
    "columns-switcher_title_to_view": "Columns to view",
    "columns-switcher_title": "Columns",
    "columns-switcher_title_to_download": "Columns to download",
    "columns-switcher_select_all": "Select All",
    "columns-switcher_deselect_all": "Deselect All",
    "button_create-incidence": "Create Incidence",
    "button_create-reverse-order": "Create Reverse Logistics Order",
    button_uploading: "Uploading File...",
    button_downloading: "Downloading File...",
    "total-count": "Showing {totalcount} Results",
    "search-filter": "Search",
    "columns-filter": "Columns",
    "action-filter": "Filters",
    "success-uploaded-csv": "orders created successful",
    "success-updated-csv": "orders updated successful",
    "error-uploaded-csv_server-error": "Whoops! Sorry, we have had a problem.",
    "loading-last-three-months": "within last 3 mo.",
    views: "Views",
    creation: "DEPRECATED_Creation menu",
    table_headers: {
      id: "ID",
      reference: "Reference",
      name: "Name",
      address: "Deliv. Address",
      additional_address: "Deliv. Additional Add.",
      zip_code: "Deliv. Zip Code",
      province: "Deliv. Province",
      country: "Deliv. Country",
      pick_up_address: "Pick Up Address",
      pick_up_address_additional: "Pick Up Additional Ad.",
      delivery_zone: "Delivery zone",
      pickup_zone: "Pickup zone",
      incidence: "Last Incidence",
      customer_lastname: "Customer Last Name",
      customer_email: "Customer Email",
      customer_phone: "Customer Phone",
      reverse_logistics: "Reverse Logistics",
      rating: "Rating",
      cash_on_delivery: "Cash on Delivery",
      retail_price: "Retail Price",
      pick_up_zip_code: "Pick Up Zip Code",
      external_tracking_id: "Ext. Tracking id",
      team: "Team",
      team_provider: "Team Provider",
      pod: "Pod",
      team_notes: "Team Notes",
      city: "Delivery City",
      pick_up_city: "Pick up City",
      pick_up_country: "Pick up Country",
      pick_up_province: "Pick up Province",
      customer_firstname: "Customer Name",
      operation: "Operation",
      client: "Client",
      delivery_time_from: "Delivery From",
      delivery_time_to: "Delivery To",
      time_from: "Time From",
      time_to: "Time To",
      delivery_estimated_duration: "Est. Time",
      picked_up_at: "Picked Up At",
      num_of_packages: "Packages",
      weight: "Weight",
      volume: "Volume",
      service: "Service",
      status: "Status",
      created_at: "Created at",
      shipped_at: "Shipped at",
      client_order_id: "Client Order ID",
      extra_info: "Extra Info",
      metadata: "Metadata",
      tags: "Tags",
      related_order_id: "Linked Ref. ID",
      updated_at: "Updated at",
      deleted_at: "Deleted at",
      plataform: "Platform",
      provider: "Provider",
      delivery_zone_id: "Delivery Zone ID",
      grp_order: "Order",
      grp_client: "GOI Client",
      grp_pickup: "Pick up",
      grp_delivery: "Delivery",
      grp_content: "Content",
      grp_extras: "Extras",
      grp_delivery_time: "Delivery Time",
      distance_from_pick_up: "Distance From Pick Up",
      distance_from_delivery_zone: "Distance From Delivery Zone",
      distance_from_province: "Distance From Province",
      pick_up_zone_id: "Pick Up Zone ID",
      locations: "Storage Locations",
      linehauls: "Linehauls",
      first_attempt_at: "First Attempt At",
      closed_at: "Closed At",
      articles_count: "Articles",
      provider_id: "Provider ID",
      commitment_date: "Commitment",
      lat: "Latitude",
      lng: "Longitude",
    },
    "delivery-notes_success":
      "Processing request. In {time} on {email} will receive the link",
    "shipping-labels_success":
      "Processing request. In {time} on {email} will receive the link",
    "download-orders-email_success":
      "Processing request. On {email} will receive the link",
    "my-pin": "Pin!!",
    "my-pin_image-caption": "View orders in the form you love at",
    "my-pin_main-title":
      "The data you most work with in the way you better prefer",
    "my-pin_p1-title":
      "Save a custom view of the columns you would like to see displayed",
    "my-pin_p2-title":
      "Combine columns and most common filters to have always data on hand",
    "my-pin_p3-title":
      "Record one custom view for orders. Change it as many times as you need",
    "my-pin_p1-text":
      "Click on columns button, next to the search field, to display a list of all available columns related to all orders. Just activate or deactive them to apply selection",
    "my-pin_p2-text":
      "You can create a custom view for orders to have them always on hand. To create this custom view just choose the columns you want to be displayed for your custom data table of orders. Then set up the filters you would apply. You see now your choices applied to your orders as usual, right?",
    "my-pin_p3-text":
      "The Pin This feature is only available to create just one custom view. Whatever changes you’ve made on columns and/or filters, by clicking on Pin This button will automatically save them as an ad-hoc view for you, to don’t have do it again later",
    "my-pin_p1-text-1":
      "You will see then the table data with orders has changed according to your choice. If you want to keep this type of view as your regular one, click on the new blue",
    "my-pin_p1-text-2": "PIN this button",
    "my-pin_p1-text-3":
      " displayed and current setup of columns will be saved to be available anytime through",
    "my-pin_p1-text-4": "tab",
    "my-pin_p2-text-1": "Would you like to save this as a custom view?",
    "my-pin_p2-text-2": "Just click on the new blue",
    "my-pin_p2-text-3": "PIN this button",
    "my-pin_p2-text-4":
      "Columns and filters will be saved keeping them available through",
    "my-pin_p3-text-1": "However, this feature only gives you the chance to",
    "my-pin_p3-text-2": "Pin and Save only one setup",
    "my-pin_p3-text-3":
      "so if you Pin new filters, for example, the previous one you had will be deleted and changed for the new one",
    "my-pin_btn-cancel": "Cancel",
    "my-pin_btn-save": "Save new Pin",
    "my-pin_tooltip_question-title":
      "You're about to delete your current Pin and save a new one",
    "my-pin_tooltip_question-content":
      "Is necessary to delete previous Pin to save new setups. Click on cancel if you don't want to delete your current pin",
    "my-pin_btn-close": "close",
    "my-pin_tooltip_success-title": "Your Pin has been created",
    "my-pin_tooltip_success-content":
      "Your setup has been saved and every time you visit My Pin tab, you will have the info presented according to your setup columns and/or filters you pin and saved",
    wizRouteExports: {
      text_header_1: "Choose a project",
      text_header_2: "Choose a date",
      summary_title:
        "{numOrdersOk} of {numOrders} orders exported for planing routes!",
      "summary_title-line-hauls": "orders exported to linehauls project",
      summary_description_1:
        "Start planning on Routing or in case of export errors, check the failed orders on a new browser tab.",
      summary_description_2:
        "If some orders couldn’t be exported this usually occurs when orders have been previously added to currently active projects.",
      "summary_description_1-linehaul":
        "Remember you are still able to add more orders or to delete them, anytime.",
      "summary_description_2-linehaul":
        "Go to Linehaul and click on “View Details” at table list to edit the project.",
      "summary-cancel": "Close",
      "summary-check": "Check failed orders",
      "summary-routing": "Open on routing",
      new_project_title: "Create New Project",
      "new_project_label-name": "Project Name",
      "new_project_name-placeholder": "You can reuse current projects' names",
      "new_project_label-date": "Date for the route",
      "new_project_footer-cancel": "Cancel",
      "new_project_footer-accept": "Accept",
      "no-projects_title": "No projects were still created",
      "no-projects_subtitle":
        "To export orders, start by creating a new project to work with",
      "new-project-error_title": "It seems a similar project already exists.",
      "new-project-error_text-1":
        "We found a project with same name and date that already exists on the records.",
      "new-project-error_text-2":
        "Modify details to create a different one from existing or move backward to select the current existing from the projects list.",
      "new-project-error-cancel": "Cancel",
      "new-project-error-view": "View Projects",
      "new-project-error-modify": "Modify Details",
      "project-selector_header": "Choose a project",
      "project-selector_footer-cancel": "Cancel",
      "project-selector_footer-create": "Create new project",
      "project-selector_footer-select": "Select",
      "linehaul-date-selector-choose": "Choose a date",
      "linehaul-date-selector-footer-cancel": "Cancel",
      "linehaul-date-selector-footer-today": "Today",
      "linehaul-date-selector-footer-accept": "Accept",
      "summary-linehaul_close": "Stay Doing on Brain",
      "summary-linehaul_go-linehaul": "Go to Linehaul",
    },
    wizBulkActions: {
      status_name: "Change Status",
      status_description: "Apply a new status to multiple orders",
      comments_name: "Add Comment",
      comments_description: "Add and apply a comment to multiple orders",
      incidences_name: "Open Incidence",
      incidences_description: "Open same incidence to multiple orders",
      "order-detail_name": "Change Order Details",
      "order-detail_description":
        "Change Operation, Delivery from/to, team and others",
      tags_name: "Add Tags",
      tags_description: "Apply same Tags to multiple orders",
      operation_name: "Operation",
      operation_description: "Select other Operation to multiple orders.",
      deliveryFromTo_name: "Delivery from / to",
      deliveryFromTo_description: "Modify date to apply to multiple orders.",
      estimatedTime_name: "Estimated Time",
      estimatedTime_description:
        "Modify the time estimation to multiple orders.",
      teams_name: "Team",
      teams_description: "Change team assignment to multiple orders.",
      services_name: "Services",
      services_description: "Modify services to multiple orders",
      delivery_from: "Delivery From",
      delivery_to: "Delivery To",
      modified: "Orders will be modified",
      operations_title: "New Operation",
      "delivery_from-to_title": "New Delivery From/To",
      estimatedTime_title: "New Estimated Time",
      team_title: "New Team",
      services_title: "New Services",
      title_delivery: "Change Delivery date and time",
      btn_delivery: "Change",
      "label_delivery-reason": "Specify a reason for the change",
      "placeholder_delivery-reason": "Chose from the list",
      attachment_name: "Attach a File",
      attachment_description:
        "Upload a file to be available for multiple orders",
      recalc_billing: "Recalculate Billing",
      recalc_billing_description:
        "Recalculate billing items on multiple dedicated services",
    },
  },
  detail: {
    "button_create-reverse-order": "Create Reverse Logistics Order",
    title: "Order",
    title_route: "Active Route",
    title_metadata: "Metadata",
    title_comments: "Comments",
    "title_delivery-logs": "Delivery Logs",
    title_service: "Service",
    title_rating: "Rating",
    title_zone_code: "Zone Codes",
    title_warehouse_locations: "Warehouse Locations",
    client_order_id: "Client Order ID",
    warehouse_not_especified: "--",
    "title_customer-address": "Address for Delivery",
    "title_customer-address-reverse": "Address for Pick up",
    alert_text: "Incidence",
    "saved-changes": "Saved!",
    "saving-changes": "Saving Changes...",
    "unsaved-changes": "Changes must be yet saved!",
    "fields-on-error": "Some fields are incorrect!",
    "success-updated-log": 'Status "{name}" updated successful',
    "error-updated-log": 'Date "{date}" is invalid',
    "unsolved-incidences": "incidence | incidences",
    "solved-incidences": "incidences solved",
    "not-incidences": "incidences",
    "label-incidences": "Incidences",
    "last-edition": "Last Updated",
    "edited-on": "Edited on",
    "publish-order": "Publish Order",
    "not-metadata": "Not metadata yet",
    save: "Save Changes",
    "tooltip-clipboard": "Copy to clipboard",
    "tooltip-show-log": "Show log",
    "route_not-active": "Not on any route yet",
    not_routed: "Order waiting to be routed",
    text_not_routed:
      "This order is not been yet assigned to any team. As soon as is routed it will be displayed here. Check it later, please.",
    "text_current-stop": "Current Stop",
    "order-stop": "This order’s stop",
    "text_stop-info_ref": "Ref",
    "text_stop-info_client": "Client Order ID",
    "text_stop-info_time": "Delivery Time",
    downloading: "Downloading",
    generating: "Generating",
    preview: "Preview. If file is not PDF or image, it will be downloaded",
    progress: "In Progress...",
    "print-file": "Print a Copy",
    "label_delivery-notes": "Delivery Notes",
    "label_shipping-labels": "Shipping Labels",
    "loading-log": "Loading log",
    articles: "Articles",
    "no-articles": "There's no items to be displayed",
    "historical-changes": " View historical changes",
    error_msg_no_related: "No linked references yet",
    tabs: {
      customer: "Customer",
      content: "Content",
      logistics: "Logistics",
      attachments: "Docs Attached",
      comments: "Comments",
      metadata: "Metadata",
      linked: "Linked Ref.",
      routing: "Routing",
      invoicing: "Invoicing",
    },
    wizardStatusFailed: {
      text_header_1: "Choose the incidence reason",
      text_header_2: "Choose a date for this incidence:",
      text_header_3: "What' the priority for the incidence ?",
      text_header_4: "Almost done, order has changes!",
      text_header_5: "Done, the incidence has been recorded!",
      cancel: "Cancel",
      set_now: "Set now",
      accept: "Accept",
      finish: "Finish",
      close: "Close",
      open_incidence: "Open incidence detail",
      choose_incidence: "Choose incidence date",
      save_status: "Save status",
      save_all: "Save all",
      summary:
        "You can close now. However if you would like to continue editing this incidence, open its detail page.",
      ordersHasChanges:
        "Order has changes. Would you want to save the Status and the Order state, or only the Status ? If you save only the Status state, Order changes will be lost",
    },
    "title_last-mile": "Last Mile",
    title_projects: "Projects",
    label_team: "Team:",
    label_project: "Project:",
    "text_not-assigned": "Not asigned yet",
    label_linehaul: "Linehaul:",
    "linehaul_text_not-assigned": "[Not included in any linehauls]",
    label_dates: "Dates:",
    label_transport: "Transport:",
    label_origin: "Origin Platform:",
    label_destination: "Destination Platform:",
    label_status: "Status:",
    "show-routes": "Show More",
    "hide-routes": "Show Less",
    "link-last-mile": "Open on Last Mile",
    label_tags: "Tags",
    label_header: "Create or Select current tags:",
    label_selected_tags: "Current Tags:",
    label_suggested_tags: "Suggestions:",
    add_article: "Add article",
    save_article: "Save article",
    add_article_title: "Add new article",
    logistics_section: {
      date_logs: {
        title: "Date logs",
        label_shipped_at: "Shipped at",
        label_picked_up_at: "Picked up at",
      },
      service_scheduled: {
        title: "Service scheduled",
        label_date_service: "Date of Service",
        label_time_from: "Time from",
        label_time_to: "Time to",
        label_delivery_estimation: "Delivery Estimation:",
        edit: "Edit",
        save: "Save",
        saved: "Saved",
        label_reason: "Reason to change",
        reason_placeholder: "Type to find",
        error_date: "Select a different day to go next",
      },
      event_logs: {
        title: "Event Log",
        expans_btn: "View more",
        no_logs: "No changes yet for this service.",
      },
    },
    title_invoicing: " Extra invoicing concepts",
    invoicing_value: "Valor",
    add_invoicing: "Add new concept",
  },
  creation: {
    title_create: "Create Order",
    title_operation: "Operation",
    client: "Client",
    "inverse-logistics": "Logistics Order Type",
    delivery: "Delivery",
    "reverse-logistic": "Reverse Logistic",
    "customer-details": "Customer Details",
    "address-delivery": "Address for Delivery",
    "address-pickup": "Address for Pick up",
    "content-details": "Content Details",
    dates: "Schedule Dates",
    "logistics-details": "Logistics Details",
    "cash-delivery": "Cash on delivery?",
    notes: "Add a note for internal teams",
    "tracking-id": "External Tracking ID",
    "error-created_server-error": "Something fail while creating the order",
    "creation-save": "Publish Order",
    "btn-cancel": "Cancel",
    "yes-cash": "Yes",
    "no-cash": "No",
    articles: "Articles",
    "articles-error": "Check section data coherence and title annotations",
    "articles-label": "(max total quantity: 500)",
    mediamarkt: {
      title_create: "Create Order",
      title_operation: "Operation",
      title_customer_details: "Customer Details",
      title_address: "Address for Delivery",
      title_delivery_type: "Delivery Type",
      title_service_type: "Service Type",
      title_additional_services: "Additional Services",
      title_articles: "Article",
      title_notes: "Notes",
      label_input_client_id: "Client order ID",
      label_input_operation: "Operation",
      label_input_name: "Name",
      label_input_last_name: "Last name",
      label_input_mail: "Email",
      label_input_phone: "Phone",
      label_input_address: "Customer Address",
      label_input_additional_info: "Additional Info",
      label_input_city: "City",
      label_input_zip: "Zip code",
      label_input_province: "Province",
      label_input_country: "Country",
      placeholder_input_client_id: "Insert client order id",
      placeholder_input_name: "Insert Name",
      placeholder_input_last_name: "Insert Last Name",
      placeholder_input_mail: "Insert Email",
      placeholder_input_phone: "Insert your phone",
      placeholder_input_address: "Insert Customer Address, nº...",
      placeholder_input_additional_info: "Additional info",
      placeholder_input_city: "Madrid",
      placeholder_input_zip: "28012",
      placeholder_input_province: "Insert Province",
      placeholder_input_country: "Select Country",
    },
  },
  form: {
    field_country: "Country",
    field_country_placeholder: "Select Country",
    "field_country_error-required": "Required field",
    "field_country_error-must-contains": "Value not found",
    field_skills: "Skills",
    field_skills_placeholder: "Select Skills",
    "field_skills_error-required": "Required field",
    "field_skills_error-must-contains": "Value not found",
    section_customer: "Contact Details",
    section_description: "Description",
    section_metadata: "Metadata",
    field_operation: "Operation",
    field_operation_placeholder: "Select Operation",
    "field_operation_error-required": "Required field",
    "field_operation_error-must-contains": "Value not found",
    field_name: "Name",
    field_name_placeholder: "Insert Name",
    "field_name_error-required": "Required field",
    "field_last-name": "Last Name",
    "field_last-name_placeholder": "Insert Last Name",
    field_email: "Email",
    field_email_placeholder: "Insert Email",
    field_email_error: "Check your email, there seems to be an error",
    field_phone: "Phone",
    field_phone_placeholder: "+34 678 894 123",
    "field_phone_error-required": "Required field",
    "field_phone_error-length": "Phone length equal to 15",
    "field_phone_error-format": "Phone not format correct",
    field_address: "Customer Address",
    field_address_placeholder: "Insert Customer Address, nº...",
    "field_address_error-required": "Required field",
    "field_address_error-zip-required": "Address must be contain a postal code",
    "field_aditional-address": "Customer Address - additional info",
    "field_aditional-address_placeholder": "4th floor",
    "field_aditional-address_error-required": "Required field",
    section_order: "Order Information",
    field_packages: "Packages",
    "field_packages-total": "Total",
    field_status: "Current Status",
    field_packages_placeholder: "Insert Packages",
    "field_packages_error-numeric": "Packages are not valid",
    "field_packages_error-required": "Required field",
    "field_packages_error-min": "Min value: 1",
    field_weight: "Total Kg.",
    field_retail: "Retail Price",
    field_zip: "ZIP Code",
    field_zip_placeholder: "28012",
    "field_zip_error-required": "Required field",
    "field_zip_error-numeric": "Zip is not valid",
    "field_zip_error-length": "Length field equal to 5",
    "field_text_area_error-length": "Max length field equal to 2045",
    field_city: "City",
    field_city_placeholder: "Madrid",
    field_province: "Province",
    field_province_placeholder: "Madrid",
    "field_city_error-required": "Required field",
    field_weight_placeholder: "Insert Weight",
    field_width_placeholder: "Insert Width",
    field_height_placeholder: "Insert Height",
    field_length_placeholder: "Insert Length",
    field_retail_placeholder: "Insert Retail Price",
    "field_weight_error-numeric": "Weight is not valid",
    "field_retail_error-numeric": "Retail price is not valid",
    field_volume: "Total m3",
    field_volume_placeholder: "Insert Volume",
    field_picked_up: "Picked up at",
    field_picked_up_placeholder: "",
    field_shipped_at: "Shipped at",
    field_shipped_at_placeholder: "",
    "field_volume_error-numeric": "Volume is not valid",
    "field_delivery-time-from": "Delivery time from",
    "field_status-time": "Status time",
    "field_delivery-time-from_placeholder": "Delivery Time From",
    "field_delivery-time-from_error-date": "Date is not valid",
    "field_delivery-time-to": "Delivery time to",
    "field_delivery-time-to_placeholder": "Delivery Time To",
    "field_delivery-time-status_placeholder": "Select Status time",
    "field_status-now": "Now",
    field_order_reference: "Reference",
    "field_delivery-time-to_error-date": "Date is not valid",
    "field_delivery-time-to_error-same_day-after_to":
      "Date must be in the same day and after Delivery From",
    "field_delivery-time-from_error-same_day-after_from":
      "Date must be in the same day and before Delivery To",
    field_duration: "Delivery Estimation",
    field_duration_placeholder: "Delivery Estimation",
    "field_duration_error-numeric": "Duration is not valid",
    "field_invoice-reference": "Invoice Reference",
    "field_invoice-reference_placeholder": "Insert Invoice Reference",
    "field_invoice-reference_error-alphanum": "Invoice is not valid",
    "field_client-order": "Client order ID",
    "field_client-order_placeholder": "Insert Client order ID",
    "field_external-tracking": "External Tracking ID",
    "field_external-tracking_placeholder": "Insert External Tracking ID",
    field_service: "Service Type",
    field_service_placeholder: "Select Type",
    "field_service_error-required": "Required field",
    field_team: "Team Assignment",
    field_team_placeholder: "Select Team",
    field_provider: "Provider Assignment",
    field_provider_placeholder: "Select Provider",
    "field_team_error-must-contains": "Value not found",
    field_notes: "Notes",
    field_notes_placeholder: " Insert Notes",
    field_attachments: "Additional Attachments",
    field_pod: "POD",
    field_status_placeholder: "Current Status",
    field_status_tooltip_title:
      "Select a date to specify when the status changed",
    field_status_tooltip_content:
      " You can set it up today or to choose a previous day from the calendar",
    "field_status_tooltip_btn-cancel": "Cancel",
    "field_status_tooltip_btn-today": "Set Today",
    "field_status_tooltip_btn-close": "Close",
    "field_status_tooltip_btn-date": "Choose a date",
    "field_status_error-required": "Required field",
    "field_status_error-must_exist": "Select a valid date",
    "field_tracked-at_error-required": "Required Tracked At field",
    "field_status_error-must-contains": "Value not found",
    field_cash_on_delivery: "Cash on Delivery",
    field_cash_on_delivery_placeholder: "How much?",
    "field_cash_on_delivery_error-numeric": "Cash on Delivery is not valid",
    "field_not-specify": "--",
    button_create: "Save changes",
    button_save: "Save changes",
    button_edit: "Edit",
    "button_finish-edit": "Finish Editing",
    button_saved: "Saved",
    "button_not-saved": "Not saved",
    "button_expand-content": "Expand Content",
    "button_collapse-content": "Collapse Content",
    "message-alert-reverse-logistics":
      "You are about to create a new order for Reverse Logistics by duplicating data.",
    "creating-order": "Creating order",
    search_tags: "Type to search tag",
    field_tags: "Tags Type",
    add_tags: "Type to search tag (min 3 characteres)",
    "textarea-filter-placeholder":
      "Copy refs from excel columns and paste it on the input",
    "textarea-filter-client-order-id":
      "Copy Client Order ID from excel columns and paste it on the input",
    "field_shipping-documents": "Shipping Documentation",
  },
};
