<template>
  <Combobox
    as="div"
    :by="compareBy"
    :model-value="selectedValue"
    :nullable="nullable"
    :multiple="multiple"
    :disabled="disabled"
    v-slot="{ disabled }"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <!-- COMBO LABEL -->
    <ComboboxLabel
      v-if="$slots.default"
      class="block text-sm font-medium leading-6 text-gray-900"
      ><slot></slot
    ></ComboboxLabel>
    <!-- COMBO -->
    <div :class="['relative', { 'mt-1': $slots.default }]">
      <!-- MULTIPLE: true FAKE INPUT (to show multiple selection on tags) -->
      <ComboboxButton
        as="div"
        v-if="multiple"
        data-component="multiple"
        :class="[
          'max-h-[200px] max-w-full overflow-y-auto rounded-md border pr-8',
          disabled ? 'bg-gray-50 ring-gray-200' : 'bg-white border-gray-300',
          { 'hover:cursor-pointer': !disabled },
          {
            'cursor-not-allowed bg-gray-50 text-gray-500 ring-gray-200':
              error?.exist,
          },
        ]"
      >
        <ul
          data-test="input_list-filter"
          class="flex min-h-[2.25rem] flex-row flex-wrap gap-y-1 p-2"
        >
          <li v-for="value in selectedValue" :key="value?.id">
            <div
              :title="inputDisplay(value)"
              class="badge-blue-sm mr-2 grid grid-cols-[1fr_min-content] content-center gap-x-1"
            >
              <p class="truncate">{{ inputDisplay(value) }}</p>

              <button
                v-if="!disabled"
                data-test="combobox_button_badge-delete"
                class="group relative flex items-center rounded-sm hover:bg-gray-500/20"
                @click.stop="handleRemoveValue(value?.id)"
              >
                <XMarkIcon class="h-3.5 w-3.5" aria-hidden="true" />
              </button>
            </div>
          </li>
        </ul>
        <div
          class="absolute right-0 top-0 mr-2 flex h-full items-center justify-center"
        >
          <LoaderCircle
            v-if="loading && !disabled"
            class="absolute right-0 mr-2 mt-2 h-5 w-5 animate-spin fill-blue-600 text-gray-200"
          />
          <ChevronUpDownIcon
            v-if="!loading && !disabled"
            class="h-5 w-5 text-gray-400 hover:cursor-pointer"
            aria-hidden="true"
          />
        </div>
      </ComboboxButton>
      <!-- MULTIPLE: false NORMAL INPUT and LIST -->
      <ComboboxButton v-else as="div" class="flex focus:outline-none">
        <ComboboxInput
          :class="[
            'block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 hover:cursor-pointer focus:ring-2 focus:ring-inset focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6',
            {
              'text-red-900 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500':
                error?.exist,
            },
            { 'bg-gray-50': disabled },
          ]"
          @change="handleSearch($event.target.value)"
          :display-value="inputDisplay"
          :disabled="disabled"
          :placeholder="placeholder"
        />
        <LoaderCircle
          v-if="loading && !disabled"
          class="absolute right-0 mr-2 mt-2 h-5 w-5 animate-spin fill-blue-600 text-gray-200"
        />
        <ChevronUpDownIcon
          v-if="!loading && !disabled"
          class="absolute right-0 mr-2 mt-2 h-5 w-5 text-gray-400 hover:cursor-pointer"
          aria-hidden="true"
        />
      </ComboboxButton>
      <!-- COMBO LIST -->
      <TransitionRoot
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        @after-leave="query = EMPTY_STRING"
      >
        <!-- IMPORTANTE: Aqui se usa "filteredValue" en vez de "list" porque
            list no reacciona bien reactivamente hablado con el v-if y la lista
            nunca abre -->
        <ComboboxOptions
          v-if="filteredValues?.length > 0 || multiple"
          class="absolute z-10 mt-1 w-full rounded-md bg-white text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <div v-if="multiple" class="sticky top-0 z-10 bg-white p-1">
            <ComboboxInput
              data-test="input-term"
              class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 hover:cursor-pointer focus:ring-1 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6"
              :placeholder="placeholder"
              @change="handleSearch($event.target.value)"
            />
          </div>
          <!--
            LIST
            "max-h-[300px]" Esto es de importancia capital, afecta directamente al sistema
            de virtualizacion de listas (useVirtualList) sin una altura fija o maximo de altura
            el sistema no funciona literamente y se sobre-carga.
          -->
          <div v-bind="containerProps" class="max-h-[300px] overflow-y-auto">
            <div v-bind="wrapperProps">
              <ComboboxOption
                v-for="{ index, data } in list"
                :key="index"
                :value="data"
                as="template"
                v-slot="{ active, selected }"
              >
                <li
                  data-test="combobox_item-suggestion"
                  :class="[
                    'relative cursor-pointer select-none py-2 pl-3 pr-9',
                    active ? 'bg-blue-600 text-white' : 'text-gray-900',
                  ]"
                >
                  <slot
                    name="listItem"
                    :item="data"
                    :selected="selected"
                    :active="active"
                  >
                    <span
                      :class="['block truncate', selected && 'font-semibold']"
                    >
                      {{ inputDisplay(data) }}
                    </span>
                  </slot>

                  <span
                    v-if="selected"
                    :class="[
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                      active ? 'text-white' : 'text-blue-600',
                    ]"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ComboboxOption>
            </div>
          </div>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
    <p v-if="error?.exist" class="mt-2 text-sm text-red-600">
      {{ error?.message }}
    </p>
  </Combobox>
</template>

<script setup>
import { computed, ref } from "vue";
import { EMPTY_STRING } from "@/common/helpers/constants";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import {
  TransitionRoot,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import debounce from "lodash-es/debounce";
import LoaderCircle from "@/common/components/LoaderCircle.vue";
import { useVirtualList } from "@vueuse/core";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  listValues: { type: Array, default: () => [] },
  modelValue: {
    type: [Object, Array, String, Number, Boolean],
  },
  disabled: { type: Boolean, default: false },
  nullable: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  compareBy: { type: String, default: "id" },
  placeholder: { type: String, default: EMPTY_STRING },
  /*
   * queryFilter
   * Se usa para manipular la manera en que se filtra el listado del
   * combo en funcion del termine escrito en el input
   */
  queryFilter: {
    type: Function,
    default: (query, value) =>
      value?.name?.toLowerCase().includes(query.value.toLowerCase()),
  },
  /*
   * inputDisplay
   * Se usa para modificar la manera de donde se toma el valor que se muestra
   * en el listado del combo
   */
  inputDisplay: { type: Function, default: (val) => val?.name },
  /*
   * onSearch
   * Se ejecuta cada vez que se hace una busqueda; cuando escribimos algo en el input
   * tanto el normal como el multiple.
   * El uso mas comun seria como en Tags, que su lista de valores es dinamica. Entonce
   * cuando se escribe un termino se externaliza la busqueda al cliente.
   */
  onSearch: { type: Function },
  /*
   * onCustomValueInput
   * Se ejecuta cuando se escribe un valor. Primero se ejecuta onSearch y despues por reactividad
   * salta filterValues y si esta prop existe se puede manipular el contenido de lo que se almacena
   * como valor seleccionado.
   * Siendo muy util para casos en los que quieres seleccionar y añadir un item que no existe en la lista.
   */
  onCustomValueInput: { type: Function },
  error: { type: Object, default: () => ({}) },
});
const query = ref(EMPTY_STRING);

const selectedValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const filteredValues = computed(() => {
  if (query.value === EMPTY_STRING) {
    return props.listValues;
  } else {
    const filteredValues = props.listValues?.filter(
      props.queryFilter.bind(this, query)
    );
    if (!filteredValues?.length && props.onCustomValueInput) {
      return props.onCustomValueInput(query.value);
    }
    return filteredValues;
  }
});

const { list, containerProps, wrapperProps } = useVirtualList(filteredValues, {
  itemHeight: 36, // Esta es la altura en px de un item del listado
  overscan: 10,
});

const handleSearch = debounce((term) => {
  query.value = term;
  if (props.onSearch) props.onSearch(term);
}, 100);

function handleRemoveValue(id) {
  selectedValue.value = selectedValue.value.filter((value) => value?.id !== id);
}
</script>
