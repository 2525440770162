import { IN_CASE_NOT_AUTH } from "./index";
import type { DirectiveHook } from "vue";
import type {
  Action,
  Authentication,
  CheckCredentialClosure,
  UserDataAdapter,
} from "./index";
// import reject from "lodash-es/reject";

const getParams = (auth: Authentication) => {
  if (!auth.permissions || typeof auth.permissions !== "string") {
    throw new Error(
      '[PASSPORT] Param "permissions" is required and must be an String boolean expression not empty. Use: "list-orders || create-orders"'
    );
  }
  if (
    !auth.inCaseNotAuth ||
    (typeof auth.inCaseNotAuth !== "function" &&
      typeof auth.inCaseNotAuth !== "string")
  ) {
    throw new Error(
      '[PASSPORT] Param "inCaseNotAuth" is required and only String or function is allowed'
    );
  }
  if (
    typeof auth.inCaseNotAuth === "string" &&
    !IN_CASE_NOT_AUTH.includes(auth.inCaseNotAuth)
  ) {
    throw new Error(
      `[PASSPORT] Param "inCaseNotAuth/${
        auth.inCaseNotAuth
      }" could be [${IN_CASE_NOT_AUTH.join(",")}]`
    );
  }

  return auth;
};

const restrictElement = (el: Element, action: Action): void => {
  if (action === "hideMe") {
    el.setAttribute("style", "display: none");
  } else if (action === "disableMe") {
    el.setAttribute("style", "pointer-events: none; opacity: .5");
  }
};

const execCredentialsExp = (
  credentials: string,
  allCredentials: Array<string>
): boolean => {
  const onlyWords = credentials.match(/[a-zA-Z-_]+/gm) ?? [];
  const credentialsResolved = onlyWords.map((credential: string) => ({
    credential,
    value: allCredentials.some(
      (permission) => permission.toLowerCase() === credential.toLowerCase()
    ),
  }));
  try {
    const booleanExp = credentialsResolved.reduce(
      (curr, next) => curr.replace(next.credential, String(next.value)),
      credentials
    );
    return Function(`return ${booleanExp};`)();
  } catch {
    throw new Error(
      `[PASSPORT] Problem on expression conversion ${credentials}`
    );
  }
};

export function checkPermission(
  getUserPermissions: UserDataAdapter
): CheckCredentialClosure {
  return function (permissions = "fake-permission"): boolean {
    if (!permissions) {
      throw new Error('[PASSPORT] param "permissions" is mandatory');
    }
    const userPermissions = getUserPermissions();
    return execCredentialsExp(permissions, userPermissions);
  };
}
export function checkRole(
  getUserRoles: UserDataAdapter
): CheckCredentialClosure {
  return function (roles = "fake-role"): boolean {
    if (!roles) {
      throw new Error('[PASSPORT] param "roles" is empty');
    }
    const userRoles = getUserRoles();
    return execCredentialsExp(roles, userRoles);
  };
}

export function checkScope(
  getUserScopes: UserDataAdapter
): CheckCredentialClosure {
  return function (scopes = "fake-scope"): boolean {
    if (!scopes) {
      throw new Error('[PASSPORT] param "scopes" is empty');
    }
    const userScopes = getUserScopes();
    return execCredentialsExp(scopes, userScopes);
  };
}

export function bindHandler(
  getUserPermissions: UserDataAdapter
): DirectiveHook {
  return (el, binding) => {
    const auth = getParams(binding.value);
    const userPermissions = getUserPermissions();
    const isAuth = execCredentialsExp(auth.permissions, userPermissions);
    if (!isAuth) restrictElement(el, auth.inCaseNotAuth);
  };
}
export function getUserPermissions(): Array<string> {
  const store = globalThis.localStorage.getItem("profile");
  if (!store) return [];
  const profile = JSON.parse(store);
  // DEBUG: Si se necesitan quitar permisos
  // profile.permissions = reject(profile?.permissions, [""])
  // DEBUG: Si se necesitan añadir permisos
  /*
  profile?.permissions?.push(
    ...["recalculate-dedicated_service-invoice_line_items"]
  );
   */
  return profile?.permissions ?? [];
}

export function getUserRoles(): Array<string> {
  const store = globalThis.localStorage.getItem("profile");
  if (!store) return [];
  const profile = JSON.parse(store);
  return profile?.roles ?? [];
}

export function getUserScopes(): Array<string> {
  const store = globalThis.localStorage.getItem("profile");
  if (!store) return [];
  const profile = JSON.parse(store);
  return profile?.scopes ?? [];
}
